<template>
  <div class="sheet-wrap">
      <a-form
          autocomplete="false"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 17 }"
          :form="formSheet"
        >
        <div class="info-wrap">
           <InfoTitle title="基本信息" />
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-item label="科目">
                  <a-select
                    allow-clear
                      v-decorator="[
                      'subject',
                      { rules: [{ required: true, message: '请选择科目!' }] },
                    ]"
                    placeholder="科目"
                    style="width: 100%"
                    disabled
                  
                  >
                    <a-select-option value="CHINESE"> 中文 </a-select-option>
                    <a-select-option value="MATH"> 数学 </a-select-option>
                  </a-select>
                </a-form-item>
               
              </a-col>
             <a-col :span="8">
                <a-form-item label="班级名称">
                   <a-input disabled placeholder="班级名称" v-decorator="['formClassName']" />
                </a-form-item>
              </a-col>
             <a-col :span="8">
                <a-form-item label="班级类型">
                  <a-select
                    allow-clear
                      v-decorator="[
                      'classType',
                    ]"
                    placeholder="班级类型"
                    style="width: 100%"
                    disabled
                  >
                   <a-select-option :value="item.value" v-for="(item, index) in classTypeArr" :key="index">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
               
              </a-col>
             
               <a-col :span="8">
                <a-form-item label="课程名称">
                   <a-input disabled placeholder="课程名称" v-decorator="['courseName']" />
                </a-form-item>
              </a-col>
               <a-col :span="8">
                <a-form-item label="班级时区">
                   <a-input disabled placeholder="班级时区" v-decorator="['classTimezone']" />
                </a-form-item>
              </a-col>
            </a-row>
        </div>
          <div class="info-wrap">
           <InfoTitle title="发单课节信息" />
            <a-row :gutter="24">
              <a-col :span="8">
                  <a-form-item label="开始课节">
                    <a-select 
                    disabled
                      labelInValue
                       placeholder="请选择开始课节"
                      v-decorator="[
                      'beginSectionId',
                      { rules: [{ required: true, message: '请选择开始课节!' }] },
                    ]">
                      <a-select-option v-for="item in sectionOptions" :key="item.uuid" :value="item.uuid">
                        {{ item.sectionName }}
                      </a-select-option>
                    </a-select>
                    </a-form-item>
              </a-col>
          <a-col :span="8">
            <a-form-item label="结束课节">
               <a-select 
                 disabled
                  labelInValue
                       placeholder="请选择结束课节"
                      v-decorator="[
                      'endSectionId',
                      { rules: [{ required: true, message: '请选择结束课节!' }] },
                    ]">
                <a-select-option v-for="item in endSectionOptions" :key="item.uuid" :value="item.uuid">
                  {{ item.sectionName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
           <a-col :span="8">
                <a-form-item label="课节数量">
                   <a-input    disabled placeholder="课节数量" v-decorator="['sectionCount']" />
                </a-form-item>
              </a-col>
            <a-col :span="8">
          <a-form-item label="开始日期" >
              <a-date-picker  placeholder="请选择开始日期"
                    disabled
                      v-decorator="[
                      'courseDate',
                      { rules: [{ required: true, message: '请选择开始日期!' }] },
                    ]" style="width: 100%" />
            </a-form-item>
            </a-col>
            <a-col :span="8">
                <a-form-item label="授课语言">
                  <a-select  
                  disabled
                  v-decorator="['language']"
                    placeholder="请选择">
                    <a-select-option v-for="item in languageOptions" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
            </a-col>
           <a-col :span="8">
                <a-form-item label="期望老师授课风格">
                  <a-select 
                    disabled  
                  v-decorator="['teachingStyle']"   placeholder="请选择"  mode="multiple">
                    <a-select-option v-for="item in teachingStylesOptions" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
            </a-col>
            <a-col :span="8">
                <a-form-item
              ref="name"
              label="上课周期"
            
              :label-col="labelCol"
              :wrapper-col="{ span: 14 }"
            >
              <div class="shedule-period-wrapper">
                <a-checkbox-group @change="dayOfWeeksChange" 
                disabled
              v-decorator="[
                      'dayOfWeeksCheckbox',
                      { rules: [{ type: 'array',required: true, message: '请选择排课周期!', trigger: 'change'}] },
                    ]"  
      >
               
                  <a-row>
                    <a-col :span="8">
                      <a-checkbox :value="1"> 周一 </a-checkbox>
                    </a-col>
                    <a-col :span="10">
                      <a-time-picker
                        style="width:100%"
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.start1"
                        @change="(date, dateString) => onWeekTimeChange(date, 1)"
                        placeholder="开始"
                        :inputReadOnly="true"
                      disabled
                      />
                    </a-col>
                    <!-- <a-col :span="6">
                      <a-time-picker
                        style="width:100%"
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.end1"
                        @change="(date, dateString) => onWeekTimeChange(date, 1, true)"
                        placeholder="结束"
                        :inputReadOnly="true"
                        :disabled="!isInArr(1, form.dayOfWeeksCheckbox)"
                      />
                    </a-col> -->
                  </a-row>
                  <a-row>
                    <a-col :span="8">
                      <a-checkbox :value="2"> 周二 </a-checkbox>
                    </a-col>
                    <a-col :span="10">
                      <a-time-picker
                        style="width:100%"
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.start2"
                        @change="(date, dateString) => onWeekTimeChange(date, 2)"
                        placeholder="开始"
                        :inputReadOnly="true"
                      disabled
                      />
                    </a-col>
                    <!-- <a-col :span="6">
                      <a-time-picker
                        style="width:100%"
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.end2"
                        @change="(date, dateString) => onWeekTimeChange(date, 2, true)"
                        placeholder="结束"
                        :inputReadOnly="true"
                        :disabled="!isInArr(2, form.dayOfWeeksCheckbox)"
                      />
                    </a-col> -->
                  </a-row>
                  <a-row>
                    <a-col :span="8">
                      <a-checkbox :value="3"> 周三 </a-checkbox>
                    </a-col>
                    <a-col :span="10">
                      <a-time-picker
                        style="width:100%"
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.start3"
                        @change="(date, dateString) => onWeekTimeChange(date, 3)"
                        placeholder="开始"
                        :inputReadOnly="true"
                      disabled
                      />
                    </a-col>
                    <!-- <a-col :span="6">
                      <a-time-picker
                        style="width:100%"
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.end3"
                        @change="(date, dateString) => onWeekTimeChange(date, 3, true)"
                        placeholder="结束"
                        :inputReadOnly="true"
                        :disabled="!isInArr(3, form.dayOfWeeksCheckbox)"
                      />
                    </a-col> -->
                  </a-row>
                  <a-row>
                    <a-col :span="8">
                      <a-checkbox :value="4"> 周四 </a-checkbox>
                    </a-col>
                    <a-col :span="10">
                      <a-time-picker
                        style="width:100%"
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.start4"
                        @change="(date, dateString) => onWeekTimeChange(date, 4)"
                        placeholder="开始"
                        :inputReadOnly="true"
                        disabled
                      />
                    </a-col>
                    <!-- <a-col :span="6">
                      <a-time-picker
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                          style="width:100%"
                        v-model="weekTime.end4"
                        @change="(date, dateString) => onWeekTimeChange(date, 4, true)"
                        placeholder="结束"
                        :inputReadOnly="true"
                        :disabled="!isInArr(4, form.dayOfWeeksCheckbox)"
                      />
                    </a-col> -->
                  </a-row>
                  <a-row>
                    <a-col :span="8">
                      <a-checkbox :value="5"> 周五 </a-checkbox>
                    </a-col>
                    <a-col :span="10">
                      <a-time-picker
                        style="width:100%"
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.start5"
                        @change="(date, dateString) => onWeekTimeChange(date, 5)"
                        placeholder="开始"
                        :inputReadOnly="true"
                       disabled
                      />
                    </a-col>
                    <!-- <a-col :span="6">
                      <a-time-picker
                        style="width:100%"
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.end5"
                        @change="(date, dateString) => onWeekTimeChange(date, 5, true)"
                        placeholder="结束"
                        :inputReadOnly="true"
                        :disabled="!isInArr(5, form.dayOfWeeksCheckbox)"
                      />
                    </a-col> -->
                  </a-row>
                  <a-row>
                    <a-col :span="8">
                      <a-checkbox :value="6"> 周六 </a-checkbox>
                    </a-col>
                    <a-col :span="10">
                      <a-time-picker
                        style="width:100%"
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.start6"
                        @change="(date, dateString) => onWeekTimeChange(date, 6)"
                        placeholder="开始"
                        :inputReadOnly="true"
                        disabled
                      />
                    </a-col>
                    <!-- <a-col :span="6">
                      <a-time-picker
                        style="width:100%"
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.end6"
                        @change="(date, dateString) => onWeekTimeChange(date, 6, true)"
                        placeholder="结束"
                        :inputReadOnly="true"
                        :disabled="!isInArr(6, form.dayOfWeeksCheckbox)"
                      />
                    </a-col> -->
                  </a-row>
                  <a-row>
                    <a-col :span="8">
                      <a-checkbox :value="7"> 周日 </a-checkbox>
                    </a-col>
                    <a-col :span="10">
                      <a-time-picker
                        style="width:100%"
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.start7"
                        @change="(date, dateString) => onWeekTimeChange(date, 7)"
                        placeholder="开始"
                        :inputReadOnly="true"
                        disabled
                      />
                    </a-col>
                    <!-- <a-col :span="6">
                      <a-time-picker
                      style="width:100%"
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.end7"
                        @change="(date, dateString) => onWeekTimeChange(date, 7, true)"
                        placeholder="结束"
                        :inputReadOnly="true"
                        :disabled="!isInArr(7, form.dayOfWeeksCheckbox)"
                      />
                    </a-col> -->
                  </a-row>
                </a-checkbox-group>
              </div>
            </a-form-item>
            </a-col>
            <a-col :span="8">
                <a-form-item label="期望老师性别">
                  <a-select   
                    disabled
                  v-decorator="['gender']"   placeholder="请选择" >
                    <a-select-option v-for="item in genderArr" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
            </a-col>
            </a-row>
        </div>
       <div class="info-wrap" style="height:660px;">
           <InfoTitle title="更多信息" />
            <a-row :gutter="24">
              
           
             <a-col :span="8">
                <a-form-item label="发单有效期">
                  <a-select
                    allow-clear
                      v-decorator="[
                      'expireTime',
                      { rules: [{ required: true, message: '请选择发单有效期!' }] },
                    ]"
                    placeholder="请选择"
                    style="width: 100%"
                   
                  >
                   <a-select-option :value="item.value" v-for="(item, index) in expireTimes" :key="index">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
               
              </a-col>
              <a-col :span="8">
                <a-form-item label="发单备注">
                   <a-textarea placeholder="请输入" v-decorator="['remark']" :maxlength="255"  :rows="1" />
                </a-form-item>
               
              </a-col>
            </a-row>
          <div class="btn-wrap" style="text-align:center;padding-top:40px;">
            <a-button type="primary"  @click="sendSheet">立即发单</a-button>
          </div>
        </div>
      </a-form>
       <a-modal
        title="确认"
        :visible="visibleCancel"
        @ok="checkHoliday"
        @cancel="cancelTC"
        okText="确定"
        cancelText="放弃"
       :okButtonProps="{
        props: {
         loading:iconLoading
        },
      }"
    >
     
      <div class="issuing-wrap">
        <div style="font-size:16px;margin-bottom:8px">确定发此<span style="color:#43d186">排课单</span>吗？</div>
        <div>发单后，授课老师将进行抢单；授课老师抢单前您可取消该单</div>
      </div>
    </a-modal>
    <a-modal
        title="操作提示"
        :visible="visibleHoliday"
        @ok="sendScheduleSheet"
        @cancel="cancelScheduleSheet"
        okText="跳过该时间，完成发单"
        cancelText="取消本次发单"
       :okButtonProps="{
        props: {
         loading:holidayLoading
        },
      }"
    >
     
      <div class="issuing-wrap">
        <div style="color:red;margin-bottom:5px">注意：您选的时间处于系统维护或假期</div>
        <div>{{messageInfo}}</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import InfoTitle from '@/components/WkInfoTitle/InfoTitle';
import moment from 'moment';
import storage from 'store';
import { postReq,sendScheduleSheet,checkHoliday} from '@/api/schedule';

import { languageOptions,teachingStylesOptions } from '@/utils/const';

const roles = storage.get('roles');
const userInfo = storage.get('userInfo');

export default {
  name:'courseScheduleSheet',
components: { InfoTitle },
  data(){
    return{
    holidayLoading:false,
    visibleHoliday:false,
    messageInfo:'',
   labelCol: { span: 7 },
      wrapperCol: { span: 16 },
          minuteStep: roles.includes('TEACHING_STAFF') ? 1 : 20,

  weekTime: {
        start1: null,
        start2: null,
        start3: null,
        start4: null,
        start5: null,
        start6: null,
        start7: null,
        //   end1: null,
        // end2: null,
        //  end3: null,
        //   end4: null,
        //    end5: null,
        //     end6: null,
        //    end7: null,
        
      },
    sectionOptions:[],
    endSectionOptions:[],
       form: {
        // 搜索老师表单
        beginSectionId: null,
        endSectionId: null,
        beginDate: null,
        gender: null,
        teachingStyleList: [],
        star: null,
        language: null,
        teacherLevels: [],
        dayOfWeeksCheckbox: [],
        dayOfWeeks: {},
        formClassId: this.$route.query.classId,
        teacherId: '',
        assignedTeacher: false,
        teacherName: null,
        schedulingType: null,
      },
    courseId:'',
    paramsObj:{},
    iconLoading:false,
    visibleCancel:false,
    courseScheduleIds:[],
     genderArr:[
      {label:'男',value:'M'},
      {label:'女',value:'F'},
      {label:'不确定',value:'U'}
     ],
    classItem:{},
     expireTimes: [
         { label: '5分钟', value:300 },
        { label: '10分钟', value:600},
        { label: '20分钟', value:1200 },
        { label: '30分钟', value:1800 },
        { label: '1小时', value:3600 },
        { label: '2小时', value:7200 },
        { label: '3小时', value: 10800 },
        { label: '6小时', value:21600 },
        { label: '12小时', value:43200 },
        { label: '24小时', value:86400 },
        { label: '2天', value:172800 },
        { label: '3天', value:259200 },
        { label: '4天', value:345600},
        { label: '5天', value:432000 },
        { label: '6天', value:518400},
        { label: '7天', value:604800 },
      ],
      teachingStylesOptions,
      languageOptions,
     
       formSheet: this.$form.createForm(this),
      classTypeArr:[
        {label:'班课',value:'GROUP'},
        {label:'私教课',value:'PRIVATE'},
      ],
       
     
    }
  },
  methods:{
    moment,

cancelScheduleSheet(){
  this.visibleHoliday=false
},
      isInArr(item, arr) {
      return arr.includes(item);
    },
  cancelTC(){
     this.visibleCancel=false;
  },
  //校验此排课单中包含系统不可用时间
  checkHoliday(){
    this.iconLoading=true
    checkHoliday(this.paramsObj).then(res=>{
      console.log(res)
     
       let data=res.data;
       if(data.intersect){
         this.visibleCancel=false
           this.iconLoading=false
          // true表示有冲突，要展示messasge中内容，false不展示，调创建排课单接口
          this.visibleHoliday=true
          this.messageInfo=data.message
       }else{
          this.sendScheduleSheet()
       }
       
    }).catch((res)=>{
              this.iconLoading=false
              this.visibleCancel=false
          })
  },
    sendScheduleSheet(){
     this.holidayLoading=true;
    sendScheduleSheet(this.paramsObj).then(res=>{
            this.$message.success("发单成功")
             this.holidayLoading=false
              this.visibleCancel=false
                this.iconLoading=false
            //调至发单管理
            this.$router.push({path:'/workoutSchedule/issuing'})
          }).catch((res)=>{
              this.holidayLoading=false
              this.visibleCancel=false
              this.iconLoading=false
          })
    },
    sendSheet(){
       this.formSheet.validateFields((err, values) => {
        if (!err) {
          const formData = values;
          let params={
            formClassId:this.classItem.formClassId,
            formClassName:values.formClassName,
            expireTime:values.expireTime,
            owner:userInfo.fullName,
            ownerId:userInfo.userID,
           relationGroupId:this.classItem.relationGroupId,
             relationSourceId:this.classItem.scheduleOrderId,
            businessData:{
            
              beginDate:this.classItem.beginDate,
              teacherLevels:this.classItem.teacherLevels,
              schedulingType:this.classItem.schedulingType,
              gender:values.gender,
              teachingStyle:values.teachingStyle,
              language:values.language?[values.language]:[],
              formClassId:this.classItem.formClassId,
              formClassName:values.formClassName,
              beginSectionId:values.beginSectionId.key,
              endSectionId:values.endSectionId.key,
              beginSectionName:values.beginSectionId.label,
              endSectionName:values.beginSectionId.label,
              sectionCount:values.sectionCount,
              courseDate:values.courseDate,
              courseType:this.classItem.courseType,
              classType:values.classType,
              subject:values.subject,
              dayOfWeeks:this.form.dayOfWeeks,
                customData:{
                  remark:values.remark
                }
            },
             type:"NORMAL_ORDER"
          }
       let flag=this.processDayOfWeeksParam(params);
  
       if(!flag){
            this.$message.warning('上课周期时间必填')
            return false
       }

          this.paramsObj=params
          this.visibleCancel=true;
        
        }
      });
    },
 processDayOfWeeksParam(params) {
    let flag=false
      const tempDayOfWeeks = {};
      for (let i = 0; i < this.form.dayOfWeeksCheckbox.length; i++) {
        const element = this.form.dayOfWeeksCheckbox[i];
        console.log(element)
        console.log(params.businessData.dayOfWeeks[element]) 
        //勾选了，时间就必填
        if(!params.businessData.dayOfWeeks[element] || params.businessData.dayOfWeeks[element][0]===null){
          flag=false
          return false
        }else{
           flag=true
        }
        tempDayOfWeeks[element] = params.businessData.dayOfWeeks[element] || [null];
      }
      params.businessData.dayOfWeeks = { ...tempDayOfWeeks };
      return flag
    },
  //获取结束课节
    onEndSectionOpen() {
      const index = this.sectionOptions.findIndex((val) => {
        return val.uuid === this.classItem.beginSectionId;
      });
      this.endSectionOptions = this.sectionOptions.slice(index < 0 ? 0 : index);
    },
      dayOfWeeksChange(value) {
      this.form.dayOfWeeksCheckbox = value;
    },
   onWeekTimeChange(date, index, setEndTime) {
      const arr = [].concat(this.form.dayOfWeeks[index]);
      if (setEndTime) {
        // 结束时间设置
        if (!arr[0]) arr[0] = null;
      //  arr[1] = date == null ? null : date.format('HH:mm');
      } else {
        // 开始时间设置
        arr[0] = date == null ? null : date.format('HH:mm');
        // arr[1] = arr[0];
        // if (!arr[1]) arr[1] = null;
        // this.weekTime[`end${index}`] = date;
      }
      this.form.dayOfWeeks[index] = arr;
    },
  },
  created(){
       window.scrollTo(0, 0); 
       this.classItem=JSON.parse(this.$route.query.classItem)
       console.log(this.classItem.dayOfWeeks)
      //初始默认时间
        if (this.classItem.dayOfWeeks) {
          let obj=this.classItem.dayOfWeeks
          for (let key in obj) {
        
            this.form.dayOfWeeksCheckbox.push(parseInt(key));
            // this.weekTime[`end${key}`] =moment(obj[key][1],'HH:mm');
            this.weekTime[`start${key}`] =moment(obj[key][0],'HH:mm');
           // 设置初始默认时间
           this.form.dayOfWeeks[key] =[obj[key][0]];
            }
         
        }
//获取课节列表
    postReq('/api/admin/scheduling/course/section/search', { courseId: this.classItem.courseId }).then((res) => {
      this.sectionOptions = res.data.content;
      this.onEndSectionOpen()
    });
  },
  mounted(){
   
    //  this.courseScheduleIds = this.classItem.scheduleArray.map((item) => {
    //   return item.uuid;
    // });
    this.form.dayOfWeeksCheckbox=this.classItem.dayOfWeeksCheckbox
      this.formSheet.setFieldsValue({
        classTimezone:this.classItem.classTimezone,
         classType:this.classItem.classType,
         formClassName:this.classItem.formClassName,
        dayOfWeeksCheckbox:this.classItem.dayOfWeeksCheckbox,
        subject:this.$route.query.subject,
        courseName: this.classItem.courseName,
        beginSectionId:{
         key:this.classItem.beginSectionId,
        
        },
        endSectionId:{
         key:this.classItem.endSectionId,
        
        },
        sectionCount:this.classItem.sectionCount,
        courseDate:this.$moment(this.classItem.courseDate).format('YYYY-MM-DD'),
        gender:this.classItem.gender,
        teachingStyle:this.classItem.teachingStyle,
        language:this.classItem.language,
      });
  }
}
</script>

<style lang="less" scoped>
.ant-form-item{
  margin-bottom: 20px;
}
.info-wrap{
  background-color: #fff;
  padding: 18px 20px 0px;
  margin-bottom: 12px;
}
.sheet-wrap{
}
.shedule-period-wrapper .ant-row {
  display: flex;
     justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.class-item{
  margin-top:9px;

}
.class-subItem{
  line-height: 24px;
}

</style>